body {
  font-family: $bodytext;
  color: #dbe2ea;
  background: #dbe2ea;

  &.ui-overlay-a {
    background: $color-green;
  }

  .row {

    &:after,
    &:before {
      content: none;
    }
  }
}

html {
  overflow: hidden!important;
}

body.ui-mobile-viewport,
div.ui-mobile-viewport {
  overflow: hidden!important;
}

* {

  a {
    color: $color-orange;

    &:focus {
      color: $color-orange;
    }
  }
}

p {
  font-family: $bodytext;
  line-height: 1.7;

  &:last-child {
    margin-bottom: 0;
  }
}

body {
  font-family: $bodytext;

  h1 {
    font-weight: 700;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $header;
  }
}

h1 {
  font-size: 2.8rem;
  line-height: 3.7rem;
}

h2 {
  font-size: 1.7rem;
  font-weight: 600;
  color: #fff;
}

h3 {
  font-size: 1.5rem;
  color: #fff;
}

h4 {
  font-size: 1.5rem;
  color: #fff;
}

a,
p a {
  color: $color-orange;

  &:hover {
    color: $color-orange;
  }
}

/* SLICKSLIDER */
.slickslider {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;

  .slick-list,
  .slick-track {
    width: 100%;
    overflow: hidden;
  }

  li {
    float: left;

    img {
      width: 100%;
    }
  }
}

.ui-page-theme-a .btn.ui-link,
.ui-page-theme-a .ui-btn.btn {
  width: calc( 100% - 2rem );
  background: $color-green-medium;
  text-align: center;
  color: #fff;
  font-weight: 600;
  border-radius: .8rem;
  display: inline-block;
  margin: 0 1rem 1rem;
  padding: .8rem;
  font-size: 1.2rem;

  &:hover,
  &:focus {
    background: $color-green-medium;
  }

  &.half {
    width: 33%;

    &.right {
      float: right;
    }
  }

  i {
    font-size: 1.6rem;
    position: relative;
    top: 4px;
  }
}

body .menu .active a {
  background: transparent;
}

body .ui-page-theme-a {
  text-shadow: none;
  color: #fff;
}

body .ui-page-theme-a .ui-btn {
  background: transparent;
  border: 0;
  color: #fff!important;
  text-shadow: none;
  box-shadow: none;
  font-size: 1.3rem;
  border-color: none;

  &:hover {
    background: transparent;
    text-shadow: none;
  }
}

.ui-footer {
  border: 0;
  background: $color-green-dark;
}


/* GLOBAL STYLES */
.page {
  min-height: 100%!important;
  height: 100%;
  overflow: hidden!important;

  &.ui-mobile.ui-page-active,
  &.ui-mobile.ui-page {
    overflow: hidden;
  }

  &.green {
    background: $color-green;

    [data-role="header"] {
      background: $color-green;
    }
  }

  &.greendark {
    background: $color-green-dark;

    [data-role="header"] {
      background: $color-green-dark;
    }
  }

  &.white {
    background: #fff;

    [data-role="header"] {
      background: $color-green;
    }
  }

  [data-role="content"] {
    text-align: center;
    padding: 1rem;
    height: 100%;

    &.no-padding {
      padding: 0;
      height: 100%;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        padding: 0rem;
      }
    }

    a {
      color: #fff;
      font-weight: 600;
    }

    img {
      padding: 0 20%;
    }

    .like {
      text-align: left;
      float: left;
      display: flex;
      align-items: center;

      img {
        padding: 0 .5rem 0 0 !important;
        width: 60px;
        height: 60px;
      }
    }

    .bubble {
      border-top-left-radius: .8rem;
      border-top-right-radius: .8rem;
      border-bottom-right-radius: .8rem;
      padding: 1rem;
      margin: 0 1.5rem .5rem;
      color: #000;
      background: #fff;
      line-height: 1.7;
      text-align: left;
      font-size: 1.1rem;

      a {
        color: #000;
        font-weight: 400;
        text-decoration: underline;
      }
    }

    .hint {
      text-align: center;
      color: #000;

      a {
        color: #000;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }

  .btn-yes,
  .btn-no,
  .btn-maybe {
    height: calc( 100% / 3 );
    width: 100%;
    display: inline-block;
    padding: 0rem 3rem;
  }

  .btn-yes {
    background: $color-green-light;
  }

  .btn-no {
    background: $color-red;
  }

  .btn-maybe {
    background: $color-orange;
  }

  &#menu {

    a {
      text-transform: uppercase;
      line-height: 2;
    }
  }

  &#page-1 {

    [data-rel="back"] {
      display: none;
    }
  }

  &#page-2-1 {

    [data-role="content"] {

      .bubble {
        z-index: 3;
      }

      img {
        margin: -55% 0 0;
        padding: 0;
      }
    }
  }

  &#page-2-3 {

    [data-role="content"] img {
      margin: -9rem 0 0;
      padding: 0 1rem;
    }
  }

  &#page-2-3-3,
  &#page-2-1-3 {

    [data-role="content"] img {
      padding: 0 40%;
      margin: -1rem 0 0;
    }
  }



  &#page-1,
  &#page-1-1,
  &#page-2-3-2,
  &#page-2-1-2,
  &#page-contact,
  &#page-2-1-1 {

    [data-role="content"] img {
      margin: -1rem 0 0;
    }
  }

  &#meer-weten,
  &#page-over-nibud,
  &#page-over-de-site,
  &#page-contact,
  &#menu {

    [data-role="content"] {
      text-align: left;
      padding: 1rem 1rem 3rem;
    }
  }

  &#page-meer-3,
  &#page-meer-2-2,
  &#page-meer-2-1 {

    [data-role="content"] {
      color: #000;

      img {
        padding: 1rem 3rem;;
      }

      .like {
        width: 100%;
        padding: 0 1rem .5rem 1rem;
      }

      div {
        padding: 1rem;
        text-align: left;

        img {
          padding: 1rem 0;
          float: left;
        }

        a {
          color: #000;
          text-decoration: underline;
          font-weight: 400;
        }
      }
    }
  }


  &#page-2-1-4,
  &#page-2-3-4 {

    [data-role="content"] {
      background: #fff;
      color: #000;
      text-align: left;
    }
  }



  [data-role=content] .logo {
    float: left;
    width: 50%;
    border-top: 1px solid $color-green;
    padding: 0;
    min-height: 70px;


    &:last-of-type {
      border-top: none!important;
    }

    &:nth-last-of-type(2),
    &:nth-last-of-type(3) {
      border-bottom: 1px solid $color-green;
    }

    img {
      padding: 0;
    }

    &:nth-child(even) {
      border-right: 1px solid $color-green;
    }
  }
}


[class|="confetti"] {
  position: absolute;
}

.red {
  background-color: #E94A3F;
}

.yellow {
  background-color: #FAA040;
}

.blue {
  background-color: #5FC9F5;
}


#loading {
  position: absolute;
  left: 11%;
  bottom: 21%;
  width: 78%;
  margin: 0 auto;
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
}




@media screen and (min-width: 39.9375em) {

  html {
    overflow: auto!important;
  }

  .container {
    width: 320px;
    margin: 75px 0 0 190px;
    border: 2px solid #fff;
    height: 550px;
    position: absolute;


    .ui-footer-fixed,
    .ui-header-fixed {
      position: absolute;
    }
    .page {
      max-width: 400px;
      position: relative;
    }
  }

  .page [data-role=content] .bubble {
    font-size: 1rem;
    padding: .8rem;
  }

  .ui-content {
    overflow: hidden;
  }

  #meer-weten .ui-content,
  #page-over-nibud .ui-content,
  #page-over-de-site .ui-content,
  #page-2-3-4 .ui-content,
  #page-2-1-4 .ui-content {
    overflow: auto;
  }

  .layover {
    background-image: url(../img/iphone.png);
    background-position: center;
    background-size: contain;
    position: absolute;
    z-index: 1001;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  #phone {
    width: 700px;
    height: 700px;
    margin: 50px auto 0;
    position: relative;
  }
}

#page-1-2.page [data-role=content].no-padding img {
  height: 100px;
}