// COLORS
$color-green-dark: #4db7b1;
$color-green-medium: #35aba4;
$color-green: #b1dedb;
$color-green-light: #add487;
$color-red: #e2686e;
$color-orange: #feb948;
$color-yellow: #ffb740;

// FONTS
$header: 'Ubuntu', serif;
$bodytext: 'Ubuntu', sans-serif;

$icons: "Font Awesome\ 5 Pro";

$transition: all .2s cubic-bezier(.17,.67,.83,.67);
