body {
  .ui-page-theme-a .ui-bar-inherit {
    background: transparent;
    color: #fff;
  }

  .ui-header .ui-title {
    padding: 0;
    font-size: 1.5rem;

    a {
      color: #fff;
    }
  }
}

.ui-page-theme-a [data-role="header"] {
  border-bottom: 1px solid #fff;
  color: #fff;

  a.ui-btn {
    font-size: 2rem;
    padding: .4rem;
  }
}
