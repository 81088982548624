.footer {
  background: #fff;
  margin: 0;
  padding: 0;
  height: 40px;

  a {
    position: absolute;
    top: -2rem;
    left: 1rem;

    img {
      width: 60px;
      height: 60px;
      margin: 0;
    }
  }
}

[data-role="footer"] {

  .progressbar {
    height: 10px;
    display: inline-block;
    background: $color-yellow;
  }
}
