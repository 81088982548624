@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1, .h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 90em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    width: 8.33333%; }
  .xlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 16.66667%; }
  .xlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    width: 25%; }
  .xlarge-push-3 {
    position: relative;
    left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    width: 33.33333%; }
  .xlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    width: 41.66667%; }
  .xlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    width: 50%; }
  .xlarge-push-6 {
    position: relative;
    left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    width: 58.33333%; }
  .xlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    width: 66.66667%; }
  .xlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    width: 75%; }
  .xlarge-push-9 {
    position: relative;
    left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    width: 83.33333%; }
  .xlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    width: 91.66667%; }
  .xlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xxlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xxlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    width: 25%; }
  .xxlarge-push-3 {
    position: relative;
    left: 25%; }
  .xxlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xxlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xxlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    width: 50%; }
  .xxlarge-push-6 {
    position: relative;
    left: 50%; }
  .xxlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xxlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xxlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    width: 75%; }
  .xxlarge-push-9 {
    position: relative;
    left: 75%; }
  .xxlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xxlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xxlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xxlarge-up-1 > .column:nth-of-type(1n), .xxlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-1 > .column:nth-of-type(1n+1), .xxlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xxlarge-up-1 > .column:last-child, .xxlarge-up-1 > .columns:last-child {
      float: left; }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xxlarge-up-2 > .column:nth-of-type(1n), .xxlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-2 > .column:nth-of-type(2n+1), .xxlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xxlarge-up-2 > .column:last-child, .xxlarge-up-2 > .columns:last-child {
      float: left; }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xxlarge-up-3 > .column:nth-of-type(1n), .xxlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-3 > .column:nth-of-type(3n+1), .xxlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xxlarge-up-3 > .column:last-child, .xxlarge-up-3 > .columns:last-child {
      float: left; }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xxlarge-up-4 > .column:nth-of-type(1n), .xxlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-4 > .column:nth-of-type(4n+1), .xxlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xxlarge-up-4 > .column:last-child, .xxlarge-up-4 > .columns:last-child {
      float: left; }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xxlarge-up-5 > .column:nth-of-type(1n), .xxlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-5 > .column:nth-of-type(5n+1), .xxlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xxlarge-up-5 > .column:last-child, .xxlarge-up-5 > .columns:last-child {
      float: left; }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xxlarge-up-6 > .column:nth-of-type(1n), .xxlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-6 > .column:nth-of-type(6n+1), .xxlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xxlarge-up-6 > .column:last-child, .xxlarge-up-6 > .columns:last-child {
      float: left; }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xxlarge-up-7 > .column:nth-of-type(1n), .xxlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-7 > .column:nth-of-type(7n+1), .xxlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xxlarge-up-7 > .column:last-child, .xxlarge-up-7 > .columns:last-child {
      float: left; }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xxlarge-up-8 > .column:nth-of-type(1n), .xxlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-8 > .column:nth-of-type(8n+1), .xxlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xxlarge-up-8 > .column:last-child, .xxlarge-up-8 > .columns:last-child {
      float: left; }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xxlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xxlarge-centered, .xxlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xxlarge-uncentered,
  .xxlarge-push-0,
  .xxlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 90em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  -webkit-flex: 0 0 8.33333%;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  -webkit-flex: 0 0 41.66667%;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  -webkit-flex: 0 0 58.33333%;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  -webkit-flex: 0 0 83.33333%;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  -webkit-flex: 0 0 91.66667%;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    -webkit-flex: 0 0 14.28571%;
        -ms-flex: 0 0 14.28571%;
            flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    -webkit-flex: 0 0 12.5%;
        -ms-flex: 0 0 12.5%;
            flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      -webkit-flex: 0 0 33.33333%;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      -webkit-flex: 0 0 20%;
          -ms-flex: 0 0 20%;
              flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      -webkit-flex: 0 0 16.66667%;
          -ms-flex: 0 0 16.66667%;
              flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      -webkit-flex: 0 0 14.28571%;
          -ms-flex: 0 0 14.28571%;
              flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      -webkit-flex: 0 0 12.5%;
          -ms-flex: 0 0 12.5%;
              flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; } }

.row.small-unstack > .column, .row.small-unstack > .columns {
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }

@media print, screen and (min-width: 40em) {
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      -webkit-flex: 0 0 33.33333%;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      -webkit-flex: 0 0 20%;
          -ms-flex: 0 0 20%;
              flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      -webkit-flex: 0 0 16.66667%;
          -ms-flex: 0 0 16.66667%;
              flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      -webkit-flex: 0 0 14.28571%;
          -ms-flex: 0 0 14.28571%;
              flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      -webkit-flex: 0 0 12.5%;
          -ms-flex: 0 0 12.5%;
              flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; } }

.row.small-unstack > .column, .row.small-unstack > .columns {
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }

@media print, screen and (min-width: 64em) {
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%; }
  .xlarge-up-2 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; }
  .xlarge-up-3 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
      -webkit-flex: 0 0 33.33333%;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xlarge-up-4 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%; }
  .xlarge-up-5 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
      -webkit-flex: 0 0 20%;
          -ms-flex: 0 0 20%;
              flex: 0 0 20%;
      max-width: 20%; }
  .xlarge-up-6 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
      -webkit-flex: 0 0 16.66667%;
          -ms-flex: 0 0 16.66667%;
              flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xlarge-up-7 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
      -webkit-flex: 0 0 14.28571%;
          -ms-flex: 0 0 14.28571%;
              flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xlarge-up-8 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
      -webkit-flex: 0 0 12.5%;
          -ms-flex: 0 0 12.5%;
              flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 75em) and (min-width: 75em) {
  .xlarge-expand {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; } }

.row.small-unstack > .column, .row.small-unstack > .columns {
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }

@media screen and (min-width: 75em) {
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

@media screen and (min-width: 90em) {
  .xxlarge-1 {
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-up-1 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%; }
  .xxlarge-up-2 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
      -webkit-flex: 0 0 50%;
          -ms-flex: 0 0 50%;
              flex: 0 0 50%;
      max-width: 50%; }
  .xxlarge-up-3 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
      -webkit-flex: 0 0 33.33333%;
          -ms-flex: 0 0 33.33333%;
              flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xxlarge-up-4 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
      -webkit-flex: 0 0 25%;
          -ms-flex: 0 0 25%;
              flex: 0 0 25%;
      max-width: 25%; }
  .xxlarge-up-5 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
      -webkit-flex: 0 0 20%;
          -ms-flex: 0 0 20%;
              flex: 0 0 20%;
      max-width: 20%; }
  .xxlarge-up-6 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
      -webkit-flex: 0 0 16.66667%;
          -ms-flex: 0 0 16.66667%;
              flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xxlarge-up-7 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
      -webkit-flex: 0 0 14.28571%;
          -ms-flex: 0 0 14.28571%;
              flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xxlarge-up-8 {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
      -webkit-flex: 0 0 12.5%;
          -ms-flex: 0 0 12.5%;
              flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 90em) and (min-width: 90em) {
  .xxlarge-expand {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; } }

.row.small-unstack > .column, .row.small-unstack > .columns {
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }

@media screen and (min-width: 90em) {
  .small-collapse > .column, .small-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .small-uncollapse > .column, .small-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; } }

.shrink {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.grid-container {
  max-width: 75rem;
  margin: 0 auto; }

.grid-container-padded {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container-padded {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }
  @media screen and (min-width: 75em) {
    .grid-container-padded {
      padding-left: 0;
      padding-right: 0; } }

.grid-x {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }

.cell {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; }
  .cell.shrink {
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > small-shrink, .grid-x > small-full, .grid-x > small-1, .grid-x > small-2, .grid-x > small-3, .grid-x > small-4, .grid-x > small-5, .grid-x > small-6, .grid-x > small-7, .grid-x > small-8, .grid-x > small-9, .grid-x > small-10, .grid-x > small-11, .grid-x > small-12 {
  -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > medium-shrink, .grid-x > medium-full, .grid-x > medium-1, .grid-x > medium-2, .grid-x > medium-3, .grid-x > medium-4, .grid-x > medium-5, .grid-x > medium-6, .grid-x > medium-7, .grid-x > medium-8, .grid-x > medium-9, .grid-x > medium-10, .grid-x > medium-11, .grid-x > medium-12 {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > large-shrink, .grid-x > large-full, .grid-x > large-1, .grid-x > large-2, .grid-x > large-3, .grid-x > large-4, .grid-x > large-5, .grid-x > large-6, .grid-x > large-7, .grid-x > large-8, .grid-x > large-9, .grid-x > large-10, .grid-x > large-11, .grid-x > large-12 {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto; } }

@media screen and (min-width: 75em) {
  .grid-x > xlarge-shrink, .grid-x > xlarge-full, .grid-x > xlarge-1, .grid-x > xlarge-2, .grid-x > xlarge-3, .grid-x > xlarge-4, .grid-x > xlarge-5, .grid-x > xlarge-6, .grid-x > xlarge-7, .grid-x > xlarge-8, .grid-x > xlarge-9, .grid-x > xlarge-10, .grid-x > xlarge-11, .grid-x > xlarge-12 {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto; } }

@media screen and (min-width: 90em) {
  .grid-x > xxlarge-shrink, .grid-x > xxlarge-full, .grid-x > xxlarge-1, .grid-x > xxlarge-2, .grid-x > xxlarge-3, .grid-x > xxlarge-4, .grid-x > xxlarge-5, .grid-x > xxlarge-6, .grid-x > xxlarge-7, .grid-x > xxlarge-8, .grid-x > xxlarge-9, .grid-x > xxlarge-10, .grid-x > xxlarge-11, .grid-x > xxlarge-12 {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .grid-x > .xlarge-auto {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    width: auto; }
  .grid-x > .xlarge-shrink {
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto; }
  .grid-x > .xlarge-1 {
    width: 8.33333%; }
  .grid-x > .xlarge-2 {
    width: 16.66667%; }
  .grid-x > .xlarge-3 {
    width: 25%; }
  .grid-x > .xlarge-4 {
    width: 33.33333%; }
  .grid-x > .xlarge-5 {
    width: 41.66667%; }
  .grid-x > .xlarge-6 {
    width: 50%; }
  .grid-x > .xlarge-7 {
    width: 58.33333%; }
  .grid-x > .xlarge-8 {
    width: 66.66667%; }
  .grid-x > .xlarge-9 {
    width: 75%; }
  .grid-x > .xlarge-10 {
    width: 83.33333%; }
  .grid-x > .xlarge-11 {
    width: 91.66667%; }
  .grid-x > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 90em) {
  .grid-x > .xxlarge-auto {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    width: auto; }
  .grid-x > .xxlarge-shrink {
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto; }
  .grid-x > .xxlarge-1 {
    width: 8.33333%; }
  .grid-x > .xxlarge-2 {
    width: 16.66667%; }
  .grid-x > .xxlarge-3 {
    width: 25%; }
  .grid-x > .xxlarge-4 {
    width: 33.33333%; }
  .grid-x > .xxlarge-5 {
    width: 41.66667%; }
  .grid-x > .xxlarge-6 {
    width: 50%; }
  .grid-x > .xxlarge-7 {
    width: 58.33333%; }
  .grid-x > .xxlarge-8 {
    width: 66.66667%; }
  .grid-x > .xxlarge-9 {
    width: 75%; }
  .grid-x > .xxlarge-10 {
    width: 83.33333%; }
  .grid-x > .xxlarge-11 {
    width: 91.66667%; }
  .grid-x > .xxlarge-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.875rem);
      margin-left: 0.9375rem;
      margin-right: 0.9375rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.875rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.875rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-x > .xlarge-auto {
      width: auto; }
    .grid-margin-x > .xlarge-shrink {
      width: auto; }
    .grid-margin-x > .xlarge-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .xlarge-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .xlarge-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .xlarge-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .xlarge-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .xlarge-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .xlarge-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .xlarge-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .xlarge-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .xlarge-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .xlarge-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .xlarge-12 {
      width: calc(100% - 1.875rem); } }
  @media screen and (min-width: 90em) {
    .grid-margin-x > .xxlarge-auto {
      width: auto; }
    .grid-margin-x > .xxlarge-shrink {
      width: auto; }
    .grid-margin-x > .xxlarge-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .xxlarge-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .xxlarge-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .xxlarge-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .xxlarge-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .xxlarge-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .xxlarge-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .xxlarge-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .xxlarge-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .xxlarge-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .xxlarge-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .xxlarge-12 {
      width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 75em) {
  .xlarge-up-1 > .cell {
    width: 100%; }
  .xlarge-up-2 > .cell {
    width: 50%; }
  .xlarge-up-3 > .cell {
    width: 33.33333%; }
  .xlarge-up-4 > .cell {
    width: 25%; }
  .xlarge-up-5 > .cell {
    width: 20%; }
  .xlarge-up-6 > .cell {
    width: 16.66667%; }
  .xlarge-up-7 > .cell {
    width: 14.28571%; }
  .xlarge-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 90em) {
  .xxlarge-up-1 > .cell {
    width: 100%; }
  .xxlarge-up-2 > .cell {
    width: 50%; }
  .xxlarge-up-3 > .cell {
    width: 33.33333%; }
  .xxlarge-up-4 > .cell {
    width: 25%; }
  .xxlarge-up-5 > .cell {
    width: 20%; }
  .xxlarge-up-6 > .cell {
    width: 16.66667%; }
  .xxlarge-up-7 > .cell {
    width: 14.28571%; }
  .xxlarge-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.875rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media screen and (min-width: 75em) {
  .grid-margin-x.xlarge-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.xlarge-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.xlarge-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.xlarge-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.xlarge-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.xlarge-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.xlarge-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.xlarge-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media screen and (min-width: 90em) {
  .grid-margin-x.xxlarge-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.xxlarge-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.xxlarge-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.xxlarge-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.xxlarge-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.xxlarge-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.xxlarge-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.xxlarge-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  .small-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .small-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .small-margin-collapse > .medium-3 {
    width: 25%; }
  .small-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .small-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .small-margin-collapse > .medium-6 {
    width: 50%; }
  .small-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .small-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .small-margin-collapse > .medium-9 {
    width: 75%; }
  .small-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .small-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .small-margin-collapse > .medium-12 {
    width: 100%; }
  .small-margin-collapse > .large-1 {
    width: 8.33333%; }
  .small-margin-collapse > .large-2 {
    width: 16.66667%; }
  .small-margin-collapse > .large-3 {
    width: 25%; }
  .small-margin-collapse > .large-4 {
    width: 33.33333%; }
  .small-margin-collapse > .large-5 {
    width: 41.66667%; }
  .small-margin-collapse > .large-6 {
    width: 50%; }
  .small-margin-collapse > .large-7 {
    width: 58.33333%; }
  .small-margin-collapse > .large-8 {
    width: 66.66667%; }
  .small-margin-collapse > .large-9 {
    width: 75%; }
  .small-margin-collapse > .large-10 {
    width: 83.33333%; }
  .small-margin-collapse > .large-11 {
    width: 91.66667%; }
  .small-margin-collapse > .large-12 {
    width: 100%; }
  .small-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .small-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .small-margin-collapse > .xlarge-3 {
    width: 25%; }
  .small-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .small-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .small-margin-collapse > .xlarge-6 {
    width: 50%; }
  .small-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .small-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .small-margin-collapse > .xlarge-9 {
    width: 75%; }
  .small-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .small-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .small-margin-collapse > .xlarge-12 {
    width: 100%; }
  .small-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .small-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .small-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .small-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .small-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .small-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .small-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .small-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .small-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .small-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .small-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .small-margin-collapse > .xxlarge-12 {
    width: 100%; }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .medium-margin-collapse > .small-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .small-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .small-3 {
      width: 25%; }
    .medium-margin-collapse > .small-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .small-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .small-6 {
      width: 50%; }
    .medium-margin-collapse > .small-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .small-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .small-9 {
      width: 75%; }
    .medium-margin-collapse > .small-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .small-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .small-12 {
      width: 100%; }
    .medium-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .medium-3 {
      width: 25%; }
    .medium-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .medium-6 {
      width: 50%; }
    .medium-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .medium-9 {
      width: 75%; }
    .medium-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .medium-12 {
      width: 100%; }
    .medium-margin-collapse > .large-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .large-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .large-3 {
      width: 25%; }
    .medium-margin-collapse > .large-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .large-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .large-6 {
      width: 50%; }
    .medium-margin-collapse > .large-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .large-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .large-9 {
      width: 75%; }
    .medium-margin-collapse > .large-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .large-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .large-12 {
      width: 100%; }
    .medium-margin-collapse > .xlarge-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .xlarge-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .xlarge-3 {
      width: 25%; }
    .medium-margin-collapse > .xlarge-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .xlarge-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .xlarge-6 {
      width: 50%; }
    .medium-margin-collapse > .xlarge-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .xlarge-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .xlarge-9 {
      width: 75%; }
    .medium-margin-collapse > .xlarge-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .xlarge-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .xlarge-12 {
      width: 100%; }
    .medium-margin-collapse > .xxlarge-1 {
      width: 8.33333%; }
    .medium-margin-collapse > .xxlarge-2 {
      width: 16.66667%; }
    .medium-margin-collapse > .xxlarge-3 {
      width: 25%; }
    .medium-margin-collapse > .xxlarge-4 {
      width: 33.33333%; }
    .medium-margin-collapse > .xxlarge-5 {
      width: 41.66667%; }
    .medium-margin-collapse > .xxlarge-6 {
      width: 50%; }
    .medium-margin-collapse > .xxlarge-7 {
      width: 58.33333%; }
    .medium-margin-collapse > .xxlarge-8 {
      width: 66.66667%; }
    .medium-margin-collapse > .xxlarge-9 {
      width: 75%; }
    .medium-margin-collapse > .xxlarge-10 {
      width: 83.33333%; }
    .medium-margin-collapse > .xxlarge-11 {
      width: 91.66667%; }
    .medium-margin-collapse > .xxlarge-12 {
      width: 100%; }
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .large-margin-collapse > .small-1 {
      width: 8.33333%; }
    .large-margin-collapse > .small-2 {
      width: 16.66667%; }
    .large-margin-collapse > .small-3 {
      width: 25%; }
    .large-margin-collapse > .small-4 {
      width: 33.33333%; }
    .large-margin-collapse > .small-5 {
      width: 41.66667%; }
    .large-margin-collapse > .small-6 {
      width: 50%; }
    .large-margin-collapse > .small-7 {
      width: 58.33333%; }
    .large-margin-collapse > .small-8 {
      width: 66.66667%; }
    .large-margin-collapse > .small-9 {
      width: 75%; }
    .large-margin-collapse > .small-10 {
      width: 83.33333%; }
    .large-margin-collapse > .small-11 {
      width: 91.66667%; }
    .large-margin-collapse > .small-12 {
      width: 100%; }
    .large-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .large-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .large-margin-collapse > .medium-3 {
      width: 25%; }
    .large-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .large-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .large-margin-collapse > .medium-6 {
      width: 50%; }
    .large-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .large-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .large-margin-collapse > .medium-9 {
      width: 75%; }
    .large-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .large-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .large-margin-collapse > .medium-12 {
      width: 100%; }
    .large-margin-collapse > .large-1 {
      width: 8.33333%; }
    .large-margin-collapse > .large-2 {
      width: 16.66667%; }
    .large-margin-collapse > .large-3 {
      width: 25%; }
    .large-margin-collapse > .large-4 {
      width: 33.33333%; }
    .large-margin-collapse > .large-5 {
      width: 41.66667%; }
    .large-margin-collapse > .large-6 {
      width: 50%; }
    .large-margin-collapse > .large-7 {
      width: 58.33333%; }
    .large-margin-collapse > .large-8 {
      width: 66.66667%; }
    .large-margin-collapse > .large-9 {
      width: 75%; }
    .large-margin-collapse > .large-10 {
      width: 83.33333%; }
    .large-margin-collapse > .large-11 {
      width: 91.66667%; }
    .large-margin-collapse > .large-12 {
      width: 100%; }
    .large-margin-collapse > .xlarge-1 {
      width: 8.33333%; }
    .large-margin-collapse > .xlarge-2 {
      width: 16.66667%; }
    .large-margin-collapse > .xlarge-3 {
      width: 25%; }
    .large-margin-collapse > .xlarge-4 {
      width: 33.33333%; }
    .large-margin-collapse > .xlarge-5 {
      width: 41.66667%; }
    .large-margin-collapse > .xlarge-6 {
      width: 50%; }
    .large-margin-collapse > .xlarge-7 {
      width: 58.33333%; }
    .large-margin-collapse > .xlarge-8 {
      width: 66.66667%; }
    .large-margin-collapse > .xlarge-9 {
      width: 75%; }
    .large-margin-collapse > .xlarge-10 {
      width: 83.33333%; }
    .large-margin-collapse > .xlarge-11 {
      width: 91.66667%; }
    .large-margin-collapse > .xlarge-12 {
      width: 100%; }
    .large-margin-collapse > .xxlarge-1 {
      width: 8.33333%; }
    .large-margin-collapse > .xxlarge-2 {
      width: 16.66667%; }
    .large-margin-collapse > .xxlarge-3 {
      width: 25%; }
    .large-margin-collapse > .xxlarge-4 {
      width: 33.33333%; }
    .large-margin-collapse > .xxlarge-5 {
      width: 41.66667%; }
    .large-margin-collapse > .xxlarge-6 {
      width: 50%; }
    .large-margin-collapse > .xxlarge-7 {
      width: 58.33333%; }
    .large-margin-collapse > .xxlarge-8 {
      width: 66.66667%; }
    .large-margin-collapse > .xxlarge-9 {
      width: 75%; }
    .large-margin-collapse > .xxlarge-10 {
      width: 83.33333%; }
    .large-margin-collapse > .xxlarge-11 {
      width: 91.66667%; }
    .large-margin-collapse > .xxlarge-12 {
      width: 100%; }
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .xlarge-margin-collapse > .small-1 {
      width: 8.33333%; }
    .xlarge-margin-collapse > .small-2 {
      width: 16.66667%; }
    .xlarge-margin-collapse > .small-3 {
      width: 25%; }
    .xlarge-margin-collapse > .small-4 {
      width: 33.33333%; }
    .xlarge-margin-collapse > .small-5 {
      width: 41.66667%; }
    .xlarge-margin-collapse > .small-6 {
      width: 50%; }
    .xlarge-margin-collapse > .small-7 {
      width: 58.33333%; }
    .xlarge-margin-collapse > .small-8 {
      width: 66.66667%; }
    .xlarge-margin-collapse > .small-9 {
      width: 75%; }
    .xlarge-margin-collapse > .small-10 {
      width: 83.33333%; }
    .xlarge-margin-collapse > .small-11 {
      width: 91.66667%; }
    .xlarge-margin-collapse > .small-12 {
      width: 100%; }
    .xlarge-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .xlarge-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .xlarge-margin-collapse > .medium-3 {
      width: 25%; }
    .xlarge-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .xlarge-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .xlarge-margin-collapse > .medium-6 {
      width: 50%; }
    .xlarge-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .xlarge-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .xlarge-margin-collapse > .medium-9 {
      width: 75%; }
    .xlarge-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .xlarge-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .xlarge-margin-collapse > .medium-12 {
      width: 100%; }
    .xlarge-margin-collapse > .large-1 {
      width: 8.33333%; }
    .xlarge-margin-collapse > .large-2 {
      width: 16.66667%; }
    .xlarge-margin-collapse > .large-3 {
      width: 25%; }
    .xlarge-margin-collapse > .large-4 {
      width: 33.33333%; }
    .xlarge-margin-collapse > .large-5 {
      width: 41.66667%; }
    .xlarge-margin-collapse > .large-6 {
      width: 50%; }
    .xlarge-margin-collapse > .large-7 {
      width: 58.33333%; }
    .xlarge-margin-collapse > .large-8 {
      width: 66.66667%; }
    .xlarge-margin-collapse > .large-9 {
      width: 75%; }
    .xlarge-margin-collapse > .large-10 {
      width: 83.33333%; }
    .xlarge-margin-collapse > .large-11 {
      width: 91.66667%; }
    .xlarge-margin-collapse > .large-12 {
      width: 100%; }
    .xlarge-margin-collapse > .xlarge-1 {
      width: 8.33333%; }
    .xlarge-margin-collapse > .xlarge-2 {
      width: 16.66667%; }
    .xlarge-margin-collapse > .xlarge-3 {
      width: 25%; }
    .xlarge-margin-collapse > .xlarge-4 {
      width: 33.33333%; }
    .xlarge-margin-collapse > .xlarge-5 {
      width: 41.66667%; }
    .xlarge-margin-collapse > .xlarge-6 {
      width: 50%; }
    .xlarge-margin-collapse > .xlarge-7 {
      width: 58.33333%; }
    .xlarge-margin-collapse > .xlarge-8 {
      width: 66.66667%; }
    .xlarge-margin-collapse > .xlarge-9 {
      width: 75%; }
    .xlarge-margin-collapse > .xlarge-10 {
      width: 83.33333%; }
    .xlarge-margin-collapse > .xlarge-11 {
      width: 91.66667%; }
    .xlarge-margin-collapse > .xlarge-12 {
      width: 100%; }
    .xlarge-margin-collapse > .xxlarge-1 {
      width: 8.33333%; }
    .xlarge-margin-collapse > .xxlarge-2 {
      width: 16.66667%; }
    .xlarge-margin-collapse > .xxlarge-3 {
      width: 25%; }
    .xlarge-margin-collapse > .xxlarge-4 {
      width: 33.33333%; }
    .xlarge-margin-collapse > .xxlarge-5 {
      width: 41.66667%; }
    .xlarge-margin-collapse > .xxlarge-6 {
      width: 50%; }
    .xlarge-margin-collapse > .xxlarge-7 {
      width: 58.33333%; }
    .xlarge-margin-collapse > .xxlarge-8 {
      width: 66.66667%; }
    .xlarge-margin-collapse > .xxlarge-9 {
      width: 75%; }
    .xlarge-margin-collapse > .xxlarge-10 {
      width: 83.33333%; }
    .xlarge-margin-collapse > .xxlarge-11 {
      width: 91.66667%; }
    .xlarge-margin-collapse > .xxlarge-12 {
      width: 100%; }
  .xlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; }
    .xxlarge-margin-collapse > .small-1 {
      width: 8.33333%; }
    .xxlarge-margin-collapse > .small-2 {
      width: 16.66667%; }
    .xxlarge-margin-collapse > .small-3 {
      width: 25%; }
    .xxlarge-margin-collapse > .small-4 {
      width: 33.33333%; }
    .xxlarge-margin-collapse > .small-5 {
      width: 41.66667%; }
    .xxlarge-margin-collapse > .small-6 {
      width: 50%; }
    .xxlarge-margin-collapse > .small-7 {
      width: 58.33333%; }
    .xxlarge-margin-collapse > .small-8 {
      width: 66.66667%; }
    .xxlarge-margin-collapse > .small-9 {
      width: 75%; }
    .xxlarge-margin-collapse > .small-10 {
      width: 83.33333%; }
    .xxlarge-margin-collapse > .small-11 {
      width: 91.66667%; }
    .xxlarge-margin-collapse > .small-12 {
      width: 100%; }
    .xxlarge-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .xxlarge-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .xxlarge-margin-collapse > .medium-3 {
      width: 25%; }
    .xxlarge-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .xxlarge-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .xxlarge-margin-collapse > .medium-6 {
      width: 50%; }
    .xxlarge-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .xxlarge-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .xxlarge-margin-collapse > .medium-9 {
      width: 75%; }
    .xxlarge-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .xxlarge-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .xxlarge-margin-collapse > .medium-12 {
      width: 100%; }
    .xxlarge-margin-collapse > .large-1 {
      width: 8.33333%; }
    .xxlarge-margin-collapse > .large-2 {
      width: 16.66667%; }
    .xxlarge-margin-collapse > .large-3 {
      width: 25%; }
    .xxlarge-margin-collapse > .large-4 {
      width: 33.33333%; }
    .xxlarge-margin-collapse > .large-5 {
      width: 41.66667%; }
    .xxlarge-margin-collapse > .large-6 {
      width: 50%; }
    .xxlarge-margin-collapse > .large-7 {
      width: 58.33333%; }
    .xxlarge-margin-collapse > .large-8 {
      width: 66.66667%; }
    .xxlarge-margin-collapse > .large-9 {
      width: 75%; }
    .xxlarge-margin-collapse > .large-10 {
      width: 83.33333%; }
    .xxlarge-margin-collapse > .large-11 {
      width: 91.66667%; }
    .xxlarge-margin-collapse > .large-12 {
      width: 100%; }
    .xxlarge-margin-collapse > .xlarge-1 {
      width: 8.33333%; }
    .xxlarge-margin-collapse > .xlarge-2 {
      width: 16.66667%; }
    .xxlarge-margin-collapse > .xlarge-3 {
      width: 25%; }
    .xxlarge-margin-collapse > .xlarge-4 {
      width: 33.33333%; }
    .xxlarge-margin-collapse > .xlarge-5 {
      width: 41.66667%; }
    .xxlarge-margin-collapse > .xlarge-6 {
      width: 50%; }
    .xxlarge-margin-collapse > .xlarge-7 {
      width: 58.33333%; }
    .xxlarge-margin-collapse > .xlarge-8 {
      width: 66.66667%; }
    .xxlarge-margin-collapse > .xlarge-9 {
      width: 75%; }
    .xxlarge-margin-collapse > .xlarge-10 {
      width: 83.33333%; }
    .xxlarge-margin-collapse > .xlarge-11 {
      width: 91.66667%; }
    .xxlarge-margin-collapse > .xlarge-12 {
      width: 100%; }
    .xxlarge-margin-collapse > .xxlarge-1 {
      width: 8.33333%; }
    .xxlarge-margin-collapse > .xxlarge-2 {
      width: 16.66667%; }
    .xxlarge-margin-collapse > .xxlarge-3 {
      width: 25%; }
    .xxlarge-margin-collapse > .xxlarge-4 {
      width: 33.33333%; }
    .xxlarge-margin-collapse > .xxlarge-5 {
      width: 41.66667%; }
    .xxlarge-margin-collapse > .xxlarge-6 {
      width: 50%; }
    .xxlarge-margin-collapse > .xxlarge-7 {
      width: 58.33333%; }
    .xxlarge-margin-collapse > .xxlarge-8 {
      width: 66.66667%; }
    .xxlarge-margin-collapse > .xxlarge-9 {
      width: 75%; }
    .xxlarge-margin-collapse > .xxlarge-10 {
      width: 83.33333%; }
    .xxlarge-margin-collapse > .xxlarge-11 {
      width: 91.66667%; }
    .xxlarge-margin-collapse > .xxlarge-12 {
      width: 100%; }
  .xxlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.625rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.625rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.625rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.625rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.625rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.625rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.625rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.625rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media screen and (min-width: 75em) {
  .xlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xlarge-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xlarge-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xlarge-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xlarge-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xlarge-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xlarge-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xlarge-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xlarge-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xlarge-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xlarge-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xlarge-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xlarge-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media screen and (min-width: 90em) {
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xxlarge-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xxlarge-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xxlarge-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xxlarge-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xxlarge-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xxlarge-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xxlarge-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xxlarge-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xxlarge-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xxlarge-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xxlarge-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xxlarge-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

.grid-y {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: column nowrap;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > small-shrink, .grid-y > small-full, .grid-y > small-1, .grid-y > small-2, .grid-y > small-3, .grid-y > small-4, .grid-y > small-5, .grid-y > small-6, .grid-y > small-7, .grid-y > small-8, .grid-y > small-9, .grid-y > small-10, .grid-y > small-11, .grid-y > small-12 {
    -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
            flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > medium-shrink, .grid-y > medium-full, .grid-y > medium-1, .grid-y > medium-2, .grid-y > medium-3, .grid-y > medium-4, .grid-y > medium-5, .grid-y > medium-6, .grid-y > medium-7, .grid-y > medium-8, .grid-y > medium-9, .grid-y > medium-10, .grid-y > medium-11, .grid-y > medium-12 {
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > large-shrink, .grid-y > large-full, .grid-y > large-1, .grid-y > large-2, .grid-y > large-3, .grid-y > large-4, .grid-y > large-5, .grid-y > large-6, .grid-y > large-7, .grid-y > large-8, .grid-y > large-9, .grid-y > large-10, .grid-y > large-11, .grid-y > large-12 {
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; } }
  @media screen and (min-width: 75em) {
    .grid-y > xlarge-shrink, .grid-y > xlarge-full, .grid-y > xlarge-1, .grid-y > xlarge-2, .grid-y > xlarge-3, .grid-y > xlarge-4, .grid-y > xlarge-5, .grid-y > xlarge-6, .grid-y > xlarge-7, .grid-y > xlarge-8, .grid-y > xlarge-9, .grid-y > xlarge-10, .grid-y > xlarge-11, .grid-y > xlarge-12 {
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; } }
  @media screen and (min-width: 90em) {
    .grid-y > xxlarge-shrink, .grid-y > xxlarge-full, .grid-y > xxlarge-1, .grid-y > xxlarge-2, .grid-y > xxlarge-3, .grid-y > xxlarge-4, .grid-y > xxlarge-5, .grid-y > xxlarge-6, .grid-y > xxlarge-7, .grid-y > xxlarge-8, .grid-y > xxlarge-9, .grid-y > xxlarge-10, .grid-y > xxlarge-11, .grid-y > xxlarge-12 {
      -webkit-flex-basis: auto;
          -ms-flex-preferred-size: auto;
              flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }
  @media screen and (min-width: 75em) {
    .grid-y > .xlarge-auto {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px;
      height: auto; }
    .grid-y > .xlarge-shrink {
      height: auto; }
    .grid-y > .xlarge-1 {
      height: 8.33333%; }
    .grid-y > .xlarge-2 {
      height: 16.66667%; }
    .grid-y > .xlarge-3 {
      height: 25%; }
    .grid-y > .xlarge-4 {
      height: 33.33333%; }
    .grid-y > .xlarge-5 {
      height: 41.66667%; }
    .grid-y > .xlarge-6 {
      height: 50%; }
    .grid-y > .xlarge-7 {
      height: 58.33333%; }
    .grid-y > .xlarge-8 {
      height: 66.66667%; }
    .grid-y > .xlarge-9 {
      height: 75%; }
    .grid-y > .xlarge-10 {
      height: 83.33333%; }
    .grid-y > .xlarge-11 {
      height: 91.66667%; }
    .grid-y > .xlarge-12 {
      height: 100%; } }
  @media screen and (min-width: 90em) {
    .grid-y > .xxlarge-auto {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px;
      height: auto; }
    .grid-y > .xxlarge-shrink {
      height: auto; }
    .grid-y > .xxlarge-1 {
      height: 8.33333%; }
    .grid-y > .xxlarge-2 {
      height: 16.66667%; }
    .grid-y > .xxlarge-3 {
      height: 25%; }
    .grid-y > .xxlarge-4 {
      height: 33.33333%; }
    .grid-y > .xxlarge-5 {
      height: 41.66667%; }
    .grid-y > .xxlarge-6 {
      height: 50%; }
    .grid-y > .xxlarge-7 {
      height: 58.33333%; }
    .grid-y > .xxlarge-8 {
      height: 66.66667%; }
    .grid-y > .xxlarge-9 {
      height: 75%; }
    .grid-y > .xxlarge-10 {
      height: 83.33333%; }
    .grid-y > .xxlarge-11 {
      height: 91.66667%; }
    .grid-y > .xxlarge-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-y > .xlarge-auto {
      height: auto; }
    .grid-margin-y > .xlarge-shrink {
      height: auto; }
    .grid-margin-y > .xlarge-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .xlarge-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .xlarge-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .xlarge-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-12 {
      height: calc(100% - 1.875rem); } }
  @media screen and (min-width: 90em) {
    .grid-margin-y > .xxlarge-auto {
      height: auto; }
    .grid-margin-y > .xxlarge-shrink {
      height: auto; }
    .grid-margin-y > .xxlarge-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .xxlarge-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .xxlarge-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .xxlarge-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame {
  width: 100vw;
  overflow: hidden;
  position: relative;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    width: 100vw;
    overflow: hidden;
    position: relative;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    width: 100vw;
    overflow: hidden;
    position: relative;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 75em) {
  .xlarge-grid-frame {
    width: 100vw;
    overflow: hidden;
    position: relative;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
  .cell .xlarge-grid-frame {
    width: 100%; }
  .xlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xlarge-cell-block-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: 100%; }
    .xlarge-cell-block-container > .grid-x {
      max-height: 100%;
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
  .xlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 90em) {
  .xxlarge-grid-frame {
    width: 100vw;
    overflow: hidden;
    position: relative;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
  .cell .xxlarge-grid-frame {
    width: 100%; }
  .xxlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xxlarge-cell-block-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    max-height: 100%; }
    .xxlarge-cell-block-container > .grid-x {
      max-height: 100%;
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
  .xxlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  height: 100vh;
  overflow: hidden;
  position: relative;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; } }

@media screen and (min-width: 75em) {
  .grid-y.xlarge-grid-frame {
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; } }

@media screen and (min-width: 90em) {
  .grid-y.xxlarge-grid-frame {
    width: auto;
    height: 100vh;
    overflow: hidden;
    position: relative;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

@media screen and (min-width: 75em) {
  .cell .grid-y.xlarge-grid-frame {
    height: 100%; } }

@media screen and (min-width: 90em) {
  .cell .grid-y.xxlarge-grid-frame {
    height: 100%; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1468a0; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  .h2,
  h3,
  .h3 {
    orphans: 3;
    widows: 3; }
  h2, .h2,
  h3,
  .h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #1779ba;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #14679e;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #1779ba;
    color: #fefefe; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fefefe; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #1779ba;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fefefe; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #1779ba;
    color: #1779ba; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #0c3d5d;
      color: #0c3d5d; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #1779ba;
        color: #1779ba; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #cacaca; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #cacaca; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::-webkit-input-placeholder {
    color: #cc4b37; }
  .is-invalid-input:not(:focus):-ms-input-placeholder {
    color: #cc4b37; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

input[type="range"] {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 0.45rem;
  margin-bottom: 0.45rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  line-height: 1;
  cursor: pointer; }
  input[type="range"]:focus {
    outline: 0; }
  input[type="range"][disabled] {
    opacity: 0.25; }
  input[type="range"]::-webkit-slider-runnable-track {
    height: 0.5rem;
    background: #e6e6e6; }
  input[type="range"]::-webkit-slider-handle {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    -webkit-appearance: none;
    background: #1779ba; }
  input[type="range"]::-moz-range-track {
    height: 0.5rem;
    -moz-appearance: none;
    background: #e6e6e6; }
  input[type="range"]::-moz-range-thumb {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    -moz-appearance: none;
    background: #1779ba; }
  input[type="range"]::-ms-track {
    height: 0.5rem;
    border: 0;
    border-top: 0.45rem solid #fefefe;
    border-bottom: 0.45rem solid #fefefe;
    background: #e6e6e6;
    overflow: visible;
    color: transparent; }
  input[type="range"]::-ms-thumb {
    width: 1.4rem;
    height: 1.4rem;
    border: 0;
    background: #1779ba; }
  input[type="range"]::-ms-fill-lower {
    background: #cacaca; }
  input[type="range"]::-ms-fill-upper {
    background: #e6e6e6; }
  output {
    vertical-align: middle;
    margin-left: 0.5em;
    line-height: 1.4rem; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  -webkit-transform: scaleY(-1);
      -ms-transform: scaleY(-1);
          transform: scaleY(-1);
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }
  .badge.primary {
    background: #1779ba;
    color: #fefefe; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: #3adb76;
    color: #0a0a0a; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fefefe; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fefefe; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fefefe; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fefefe; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    white-space: nowrap;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row; }
  .menu.vertical {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
  .menu.expanded li {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; }
  .menu.simple {
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
    .menu.medium-vertical {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; }
    .menu.medium-expanded li {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; }
    .menu.medium-simple li {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
    .menu.large-vertical {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; }
    .menu.large-expanded li {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; }
    .menu.large-simple li {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; } }
  @media screen and (min-width: 75em) {
    .menu.xlarge-horizontal {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
    .menu.xlarge-vertical {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; }
    .menu.xlarge-expanded li {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; }
    .menu.xlarge-simple li {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; } }
  @media screen and (min-width: 90em) {
    .menu.xxlarge-horizontal {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
    .menu.xxlarge-vertical {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; }
    .menu.xxlarge-expanded li {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; }
    .menu.xxlarge-simple li {
      -webkit-flex: 1 1 0px;
          -ms-flex: 1 1 0px;
              flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .menu.icon-left li a {
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    -webkit-flex-flow: column nowrap;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      -webkit-align-self: stretch;
          -ms-flex-item-align: stretch;
                  -ms-grid-row-align: stretch;
              align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    -webkit-flex-flow: column nowrap;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      -webkit-align-self: stretch;
          -ms-flex-item-align: stretch;
                  -ms-grid-row-align: stretch;
              align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.align-left {
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .menu.align-right li {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
    .menu.align-right li .submenu li {
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .menu.align-center li .submenu li {
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .menu-centered > .menu li {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .menu-centered > .menu li .submenu li {
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: -webkit-transform 0.15s linear;
  transition: transform 0.15s linear;
  transition: transform 0.15s linear, -webkit-transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
            transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
            transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: #36454f;
  color: #e6e6e6; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media screen and (min-width: 75em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.xlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media screen and (min-width: 90em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xxlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary {
    background: #767676;
    color: #fefefe; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      -webkit-flex-basis: 100%;
          -ms-flex-preferred-size: 100%;
              flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: #2C3840; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: #2C3840; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 280px;
  height: 100%;
  -webkit-transform: translateX(-280px);
      -ms-transform: translateX(-280px);
          transform: translateX(-280px);
  overflow-y: auto; }
  .off-canvas-content .off-canvas.position-left {
    -webkit-transform: translateX(-280px);
        -ms-transform: translateX(-280px);
            transform: translateX(-280px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
              transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    -webkit-transform: translateX(280px);
        -ms-transform: translateX(280px);
            transform: translateX(280px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  width: 280px;
  height: 100%;
  -webkit-transform: translateX(280px);
      -ms-transform: translateX(280px);
          transform: translateX(280px);
  overflow-y: auto; }
  .off-canvas-content .off-canvas.position-right {
    -webkit-transform: translateX(280px);
        -ms-transform: translateX(280px);
            transform: translateX(280px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
              transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    -webkit-transform: translateX(-280px);
        -ms-transform: translateX(-280px);
            transform: translateX(-280px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  -webkit-transform: translateY(-250px);
      -ms-transform: translateY(-250px);
          transform: translateY(-250px);
  overflow-x: auto; }
  .off-canvas-content .off-canvas.position-top {
    -webkit-transform: translateY(-250px);
        -ms-transform: translateY(-250px);
            transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
              transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    -webkit-transform: translateY(250px);
        -ms-transform: translateY(250px);
            transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  -webkit-transform: translateY(250px);
      -ms-transform: translateY(250px);
          transform: translateY(250px);
  overflow-x: auto; }
  .off-canvas-content .off-canvas.position-bottom {
    -webkit-transform: translateY(250px);
        -ms-transform: translateY(250px);
            transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      -webkit-transform: translate(0, 0);
          -ms-transform: translate(0, 0);
              transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    -webkit-transform: translateY(-250px);
        -ms-transform: translateY(-250px);
            transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 280px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 280px; }
  .position-right.reveal-for-medium {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 280px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 280px; }
  .position-top.reveal-for-medium {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 280px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 280px; }
  .position-bottom.reveal-for-medium {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 280px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 280px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 280px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 280px; }
  .position-right.reveal-for-large {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 280px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 280px; }
  .position-top.reveal-for-large {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 280px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 280px; }
  .position-bottom.reveal-for-large {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 280px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 280px; } }

@media screen and (min-width: 75em) {
  .position-left.reveal-for-xlarge {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xlarge {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 280px; }
    .position-left.reveal-for-xlarge ~ .off-canvas-content {
      margin-left: 280px; }
  .position-right.reveal-for-xlarge {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xlarge {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 280px; }
    .position-right.reveal-for-xlarge ~ .off-canvas-content {
      margin-right: 280px; }
  .position-top.reveal-for-xlarge {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xlarge {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 280px; }
    .position-top.reveal-for-xlarge ~ .off-canvas-content {
      margin-top: 280px; }
  .position-bottom.reveal-for-xlarge {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xlarge {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 280px; }
    .position-bottom.reveal-for-xlarge ~ .off-canvas-content {
      margin-bottom: 280px; } }

@media screen and (min-width: 90em) {
  .position-left.reveal-for-xxlarge {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xxlarge {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 280px; }
    .position-left.reveal-for-xxlarge ~ .off-canvas-content {
      margin-left: 280px; }
  .position-right.reveal-for-xxlarge {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xxlarge {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 280px; }
    .position-right.reveal-for-xxlarge ~ .off-canvas-content {
      margin-right: 280px; }
  .position-top.reveal-for-xxlarge {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xxlarge {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 280px; }
    .position-top.reveal-for-xxlarge ~ .off-canvas-content {
      margin-top: 280px; }
  .position-bottom.reveal-for-xxlarge {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xxlarge {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 280px; }
    .position-bottom.reveal-for-xxlarge ~ .off-canvas-content {
      margin-bottom: 280px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

@media screen and (min-width: 75em) {
  .off-canvas.in-canvas-for-xlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xlarge.position-left, .off-canvas.in-canvas-for-xlarge.position-right, .off-canvas.in-canvas-for-xlarge.position-top, .off-canvas.in-canvas-for-xlarge.position-bottom {
      box-shadow: none;
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas.in-canvas-for-xlarge .close-button {
      display: none; } }

@media screen and (min-width: 90em) {
  .off-canvas.in-canvas-for-xxlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xxlarge.position-left, .off-canvas.in-canvas-for-xxlarge.position-right, .off-canvas.in-canvas-for-xxlarge.position-top, .off-canvas.in-canvas-for-xxlarge.position-bottom {
      box-shadow: none;
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
    .off-canvas.in-canvas-for-xxlarge .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: none;
      touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  -webkit-transform: scale(1, -1);
      -ms-transform: scale(1, -1);
          transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  table tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #fefefe; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #2C3840;
  color: #fefefe;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  -webkit-flex: 1 1 0px;
      -ms-flex: 1 1 0px;
          flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0rem;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #2C3840; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      -webkit-flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;
              flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        -webkit-flex: 0 1 auto;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 89.9375em) {
    .top-bar.stacked-for-xlarge {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
      .top-bar.stacked-for-xlarge .top-bar-left,
      .top-bar.stacked-for-xlarge .top-bar-right {
        -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        max-width: 100%; } }
  .top-bar.stacked-for-xxlarge {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
    .top-bar.stacked-for-xxlarge .top-bar-left,
    .top-bar.stacked-for-xxlarge .top-bar-right {
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      max-width: 100%; }

.top-bar-title {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
          clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.clearfix::after {
  clear: both; }

.align-right {
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.align-center {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.align-justify {
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.align-spaced {
  -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
          justify-content: space-around; }

.align-right.vertical.menu > li > a {
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }

.align-top {
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }

.align-self-top {
  -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
          align-self: flex-start; }

.align-bottom {
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }

.align-self-bottom {
  -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
          align-self: flex-end; }

.align-middle {
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.align-self-middle {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
              -ms-grid-row-align: center;
          align-self: center; }

.align-stretch {
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.align-self-stretch {
  -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
              -ms-grid-row-align: stretch;
          align-self: stretch; }

.align-center-middle {
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center; }

.small-order-1 {
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.small-order-2 {
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2; }

.small-order-3 {
  -webkit-order: 3;
      -ms-flex-order: 3;
          order: 3; }

.small-order-4 {
  -webkit-order: 4;
      -ms-flex-order: 4;
          order: 4; }

.small-order-5 {
  -webkit-order: 5;
      -ms-flex-order: 5;
          order: 5; }

.small-order-6 {
  -webkit-order: 6;
      -ms-flex-order: 6;
          order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .medium-order-2 {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .medium-order-3 {
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .medium-order-4 {
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .medium-order-5 {
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .medium-order-6 {
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .large-order-2 {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .large-order-3 {
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .large-order-4 {
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .large-order-5 {
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .large-order-6 {
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; } }

@media screen and (min-width: 75em) {
  .xlarge-order-1 {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .xlarge-order-2 {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .xlarge-order-3 {
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .xlarge-order-4 {
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .xlarge-order-5 {
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .xlarge-order-6 {
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; } }

@media screen and (min-width: 90em) {
  .xxlarge-order-1 {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .xxlarge-order-2 {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .xxlarge-order-3 {
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .xxlarge-order-4 {
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .xxlarge-order-5 {
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .xxlarge-order-6 {
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; } }

.flex-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flex-child-auto {
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto; }

.flex-child-grow {
  -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto; }

.flex-child-shrink {
  -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto; }

.flex-dir-row {
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row; }

.flex-dir-row-reverse {
  -webkit-flex-direction: row-reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }

.flex-dir-column {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column; }

.flex-dir-column-reverse {
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .medium-flex-child-auto {
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
  .medium-flex-child-grow {
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
  .medium-flex-child-shrink {
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
  .medium-flex-dir-row {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row; }
  .medium-flex-dir-row-reverse {
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
  .medium-flex-dir-column {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
  .medium-flex-dir-column-reverse {
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .large-flex-child-auto {
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
  .large-flex-child-grow {
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
  .large-flex-child-shrink {
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
  .large-flex-dir-row {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row; }
  .large-flex-dir-row-reverse {
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
  .large-flex-dir-column {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
  .large-flex-dir-column-reverse {
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; } }

@media screen and (min-width: 75em) {
  .xlarge-flex-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .xlarge-flex-child-auto {
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
  .xlarge-flex-dir-row {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; } }

@media screen and (min-width: 90em) {
  .xxlarge-flex-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .xxlarge-flex-child-auto {
    -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto; }
  .xxlarge-flex-dir-row {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; } }

.text-hide {
  font: 0/0 a !important;
  color: transparent !important;
  text-shadow: none !important;
  background-color: transparent !important;
  border: 0 !important; }

.text-truncate {
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-wrap {
  word-wrap: break-word !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-overline {
  text-decoration: overline !important; }

.text-underline {
  text-decoration: underline !important; }

.text-line-through {
  text-decoration: line-through !important; }

.font-wide {
  letter-spacing: 0.25rem; }

.font-normal {
  font-weight: normal; }

.font-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic !important; }

ul.list-disc {
  list-style-type: disc !important; }

ul.list-circle {
  list-style-type: circle !important; }

ul.list-square {
  list-style-type: square !important; }

ol.list-decimal {
  list-style-type: decimal !important; }

ol.list-lower-alpha {
  list-style-type: lower-alpha !important; }

ol.list-lower-latin {
  list-style-type: lower-latin !important; }

ol.list-lower-roman {
  list-style-type: lower-roman !important; }

ol.list-upper-alpha {
  list-style-type: upper-alpha !important; }

ol.list-upper-latin {
  list-style-type: upper-latin !important; }

ol.list-upper-roman {
  list-style-type: upper-roman !important; }

.rounded {
  border-radius: 5000px !important; }

.radius {
  border-radius: 0.1875rem; }

.bordered {
  border: 0.0625rem solid #cacaca; }

.shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.arrow-down {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent; }

.arrow-up {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0a0a0a; }

.arrow-right {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0a0a0a; }

.arrow-left {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4375rem;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0a0a0a transparent transparent; }

.separator-center {
  text-align: center !important; }
  .separator-center::before, .separator-center::after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .separator-center::after {
    clear: both; }
  .separator-center::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0; }

.separator-left {
  text-align: left !important; }
  .separator-left::before, .separator-left::after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .separator-left::after {
    clear: both; }
  .separator-left::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0;
    margin-left: 0 !important; }

.separator-right {
  text-align: right !important; }
  .separator-right::before, .separator-right::after {
    display: table;
    content: ' ';
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .separator-right::after {
    clear: both; }
  .separator-right::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #1779ba;
    margin: 1rem auto 0;
    margin-right: 0 !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.display-inline {
  display: inline !important; }

.display-inline-block {
  display: inline-block !important; }

.display-block {
  display: block !important; }

.display-table {
  display: table !important; }

.display-table-cell {
  display: table-cell !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-fixed-top {
  position: fixed !important;
  top: 0rem !important;
  right: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.position-fixed-bottom {
  position: fixed !important;
  right: 0rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  z-index: 975; }

.border-box {
  box-sizing: border-box !important; }

.border-none {
  border: none !important; }

.width-25 {
  width: 25% !important; }

.width-50 {
  width: 50% !important; }

.width-75 {
  width: 75% !important; }

.width-100 {
  width: 100% !important; }

.height-25 {
  height: 25% !important; }

.height-50 {
  height: 50% !important; }

.height-75 {
  height: 75% !important; }

.height-100 {
  height: 100% !important; }

.max-width-100 {
  max-width: 100% !important; }

.max-height-100 {
  max-height: 100% !important; }

.margin-0 {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important; }

.padding-0 {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important; }

.margin-top-0 {
  margin-top: 0rem !important; }

.padding-top-0 {
  padding-top: 0rem !important; }

.margin-right-0 {
  margin-right: 0rem !important; }

.padding-right-0 {
  padding-right: 0rem !important; }

.margin-bottom-0 {
  margin-bottom: 0rem !important; }

.padding-bottom-0 {
  padding-bottom: 0rem !important; }

.margin-left-0 {
  margin-left: 0rem !important; }

.padding-left-0 {
  padding-left: 0rem !important; }

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important; }

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.margin-1 {
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important; }

.padding-1 {
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important; }

.margin-top-1 {
  margin-top: 1rem !important; }

.padding-top-1 {
  padding-top: 1rem !important; }

.margin-right-1 {
  margin-right: 1rem !important; }

.padding-right-1 {
  padding-right: 1rem !important; }

.margin-bottom-1 {
  margin-bottom: 1rem !important; }

.padding-bottom-1 {
  padding-bottom: 1rem !important; }

.margin-left-1 {
  margin-left: 1rem !important; }

.padding-left-1 {
  padding-left: 1rem !important; }

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.margin-2 {
  margin-top: 2rem !important;
  margin-right: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 2rem !important; }

.padding-2 {
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 2rem !important; }

.margin-top-2 {
  margin-top: 2rem !important; }

.padding-top-2 {
  padding-top: 2rem !important; }

.margin-right-2 {
  margin-right: 2rem !important; }

.padding-right-2 {
  padding-right: 2rem !important; }

.margin-bottom-2 {
  margin-bottom: 2rem !important; }

.padding-bottom-2 {
  padding-bottom: 2rem !important; }

.margin-left-2 {
  margin-left: 2rem !important; }

.padding-left-2 {
  padding-left: 2rem !important; }

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.margin-3 {
  margin-top: 3rem !important;
  margin-right: 3rem !important;
  margin-bottom: 3rem !important;
  margin-left: 3rem !important; }

.padding-3 {
  padding-top: 3rem !important;
  padding-right: 3rem !important;
  padding-bottom: 3rem !important;
  padding-left: 3rem !important; }

.margin-top-3 {
  margin-top: 3rem !important; }

.padding-top-3 {
  padding-top: 3rem !important; }

.margin-right-3 {
  margin-right: 3rem !important; }

.padding-right-3 {
  padding-right: 3rem !important; }

.margin-bottom-3 {
  margin-bottom: 3rem !important; }

.padding-bottom-3 {
  padding-bottom: 3rem !important; }

.margin-left-3 {
  margin-left: 3rem !important; }

.padding-left-3 {
  padding-left: 3rem !important; }

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(-90deg);
          transform: perspective(2000px) rotateX(-90deg);
  -webkit-transform-origin: top;
      -ms-transform-origin: top;
          transform-origin: top;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(-90deg);
          transform: perspective(2000px) rotateY(-90deg);
  -webkit-transform-origin: right;
      -ms-transform-origin: right;
          transform-origin: right;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(90deg);
          transform: perspective(2000px) rotateX(90deg);
  -webkit-transform-origin: bottom;
      -ms-transform-origin: bottom;
          transform-origin: bottom;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(90deg);
          transform: perspective(2000px) rotateY(90deg);
  -webkit-transform-origin: left;
      -ms-transform-origin: left;
          transform-origin: left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateX(-90deg);
          transform: perspective(2000px) rotateX(-90deg);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotateY(-90deg);
          transform: perspective(2000px) rotateY(-90deg);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: top;
      -ms-transform-origin: top;
          transform-origin: top;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(-90deg);
          transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: right;
      -ms-transform-origin: right;
          transform-origin: right;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(-90deg);
          transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: bottom;
      -ms-transform-origin: bottom;
          transform-origin: bottom;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(90deg);
          transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: left;
      -ms-transform-origin: left;
          transform-origin: left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(90deg);
          transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateX(-90deg);
          transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: perspective(2000px) rotate(0deg);
          transform: perspective(2000px) rotate(0deg);
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  -webkit-transform: perspective(2000px) rotateY(-90deg);
          transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
          transform: scale(0.5);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
          transform: scale(1.5);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  -webkit-transform: scale(1.5);
      -ms-transform: scale(1.5);
          transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
          transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: rotate(-0.75turn);
      -ms-transform: rotate(-0.75turn);
          transform: rotate(-0.75turn);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  -webkit-transform: rotate(0.75turn);
      -ms-transform: rotate(0.75turn);
          transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: rotate(0.75turn);
      -ms-transform: rotate(0.75turn);
          transform: rotate(0.75turn);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  -webkit-transform: rotate(-0.75turn);
      -ms-transform: rotate(-0.75turn);
          transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  -webkit-animation-name: shake-7;
          animation-name: shake-7; }

@-webkit-keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    -webkit-transform: translateX(7%);
            transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    -webkit-transform: translateX(-7%);
            transform: translateX(-7%); } }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    -webkit-transform: translateX(7%);
            transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    -webkit-transform: translateX(-7%);
            transform: translateX(-7%); } }

.spin-cw {
  -webkit-animation-name: spin-cw-1turn;
          animation-name: spin-cw-1turn; }

@-webkit-keyframes spin-cw-1turn {
  0% {
    -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn); }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0); } }

@keyframes spin-cw-1turn {
  0% {
    -webkit-transform: rotate(-1turn);
            transform: rotate(-1turn); }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0); } }

.spin-ccw {
  -webkit-animation-name: spin-cw-1turn;
          animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  100% {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

.wiggle {
  -webkit-animation-name: wiggle-7deg;
          animation-name: wiggle-7deg; }

@-webkit-keyframes wiggle-7deg {
  40%, 50%, 60% {
    -webkit-transform: rotate(7deg);
            transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    -webkit-transform: rotate(-7deg);
            transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    -webkit-transform: rotate(0);
            transform: rotate(0); } }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    -webkit-transform: rotate(7deg);
            transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    -webkit-transform: rotate(-7deg);
            transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    -webkit-transform: rotate(0);
            transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  -webkit-animation-duration: 500ms;
          animation-duration: 500ms; }

.infinite {
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

.slow {
  -webkit-animation-duration: 750ms !important;
          animation-duration: 750ms !important; }

.fast {
  -webkit-animation-duration: 250ms !important;
          animation-duration: 250ms !important; }

.linear {
  -webkit-animation-timing-function: linear !important;
          animation-timing-function: linear !important; }

.ease {
  -webkit-animation-timing-function: ease !important;
          animation-timing-function: ease !important; }

.ease-in {
  -webkit-animation-timing-function: ease-in !important;
          animation-timing-function: ease-in !important; }

.ease-out {
  -webkit-animation-timing-function: ease-out !important;
          animation-timing-function: ease-out !important; }

.ease-in-out {
  -webkit-animation-timing-function: ease-in-out !important;
          animation-timing-function: ease-in-out !important; }

.bounce-in {
  -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
          animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  -webkit-animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
          animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  -webkit-animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
          animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  -webkit-animation-delay: 300ms !important;
          animation-delay: 300ms !important; }

.long-delay {
  -webkit-animation-delay: 700ms !important;
          animation-delay: 700ms !important; }

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; }

#wpadminbar {
  position: fixed !important; }

body.admin-bar.f-topbar-fixed .sticky.fixed {
  margin-top: 2rem; }

body.admin-bar.offcanvas .off-canvas.is-open {
  top: 2.875rem; }
  @media screen and (min-width: 48.9375em) {
    body.admin-bar.offcanvas .off-canvas.is-open {
      top: 2rem; } }

.wp-caption > figcaption {
  max-width: 100%;
  font-size: 0.8rem;
  color: #999;
  padding: 0.25rem 0; }

p.wp-caption-text {
  font-size: 90%;
  color: #666;
  padding: 0.625rem 0; }

.alignleft {
  float: left;
  padding-right: 1rem;
  margin: 0; }

.alignright {
  float: right;
  padding-left: 1rem;
  margin: 0; }

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

@media print, screen and (min-width: 40em) {
  .mobile-menu,
  .site-title-bar {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .desktop-menu,
  .site-navigation .top-bar-left {
    display: none !important; } }

.site-desktop-title a {
  font-weight: bold; }

.mobile-menu {
  display: none; }

.off-canvas > ul.menu {
  height: 100vh;
  padding: 1rem; }
  .off-canvas > ul.menu a {
    color: #fefefe;
    font-weight: 600;
    font-size: 0.9375rem; }

.off-canvas .menu .active > a {
  background-color: #ccc; }

.title-bar-title a {
  font-size: 1rem;
  color: #B9B9B9; }

.mobile-menu .menu .is-active > a,
.mobile-off-canvas-menu .menu .is-active > a {
  background-color: #36454f; }

.top-bar .top-bar-title a {
  font-size: 1rem;
  color: #B9B9B9;
  padding-left: 1rem;
  line-height: 1.8; }

.top-bar .menu a {
  color: #e6e6e6;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1; }
  .top-bar .menu a:hover:not(.button) {
    background-color: #42525D; }

.top-bar .menu .active > a {
  background-color: #ccc; }

.top-bar .menu > li:not(.menu-text) > a {
  padding: 1rem; }

.top-bar .menu li:not(:last-child) {
  border-right: 1px solid #4e4e4e; }

.top-bar .dropdown.menu .submenu {
  border: 0; }

.top-bar .dropdown.menu .has-submenu.is-down-arrow a {
  padding-right: 1rem; }

.top-bar .dropdown.menu .has-submenu.is-down-arrow > a::after {
  border: 0; }

.top-bar .dropdown.menu:first-child > li.is-dropdown-submenu-parent > a::after {
  display: none; }

@media screen and (max-width: 39.9375em) {
  .site-navigation {
    padding: 0; } }

.post-navigation::before, .post-navigation::after {
  display: table;
  content: ' ';
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.post-navigation::after {
  clear: both; }

.nav-previous {
  float: left; }

.nav-next {
  float: right; }

.main-wrap {
  max-width: 75rem;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  margin-top: 1rem; }
  @media print, screen and (min-width: 40em) {
    .main-wrap {
      margin-top: 3rem; } }
  .main-wrap.sidebar-left .main-content {
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .main-wrap.sidebar-left .sidebar {
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  @media print, screen and (min-width: 40em) {
    .main-wrap.sidebar-left .main-content {
      -webkit-order: 2;
          -ms-flex-order: 2;
              order: 2; }
    .main-wrap.sidebar-left .sidebar {
      -webkit-order: 1;
          -ms-flex-order: 1;
              order: 1; } }
  .main-wrap .main-content-full-width {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
  .main-wrap .main-content {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .main-wrap .main-content {
        width: calc(66.66667% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem; } }
  .main-wrap .sidebar {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .main-wrap .sidebar {
        width: calc(33.33333% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
        margin-top: 1rem; } }

.footer-container {
  margin: 0 auto;
  border-top: 1px solid #cacaca;
  margin-top: 3.75rem; }

.footer {
  max-width: 75rem;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  padding: 1.875rem 0; }
  .footer section {
    -webkit-flex: 1 1 0px;
        -ms-flex: 1 1 0px;
            flex: 1 1 0px;
    width: auto;
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
  .footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }

body#tinymce {
  height: auto;
  max-width: 75rem;
  padding: 1.25rem !important; }

.sites-button {
  background: #1779ba;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.2) inset;
  border-radius: 3px;
  border: none; }

.cards-container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  .cards-container .card {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    .cards-container .card .card-content {
      padding: 1rem; }
    @media print, screen and (min-width: 40em) {
      .cards-container .card {
        width: calc(33.33333% - 0.7rem);
        margin-right: 0.35rem;
        margin-left: 0.35rem; } }

.featured-hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 6.25rem; }
  @media print, screen and (min-width: 40em) {
    .featured-hero {
      height: 12.5rem; } }
  @media screen and (min-width: 75em) {
    .featured-hero {
      height: 25rem; } }

.dotted, .section-divider hr {
  border-style: dashed;
  margin: 2rem 0; }

[data-whatinput="mouse"] a:focus, button:focus [data-whatinput="touch"] a:focus, button:focus {
  outline: none; }

.front-hero {
  background: url("../images/demo/hero-bg-foundation-6-small.svg") bottom center;
  background-size: cover;
  background-position: bottom;
  padding: 4.0625rem 0;
  margin: 0 0 2rem;
  height: auto;
  position: relative;
  text-align: left; }
  .front-hero .marketing {
    max-width: 55rem;
    margin: 0 auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap; }
  @media print, screen and (min-width: 40em) {
    .front-hero {
      background: url("../images/demo/hero-bg-foundation-6-large.svg") bottom center;
      background-size: cover;
      background-position: center;
      height: 42.8125rem;
      margin: 0 0 4.5rem; } }
  .front-hero .watch {
    width: calc(58.33333% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .front-hero .watch {
        width: calc(100% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem; } }
    .front-hero .watch a {
      color: #B4C9D1;
      cursor: pointer;
      font-weight: 400;
      margin-right: 1.25rem; }
    .front-hero .watch a:hover {
      color: #fff; }
    .front-hero .watch #stargazers :before {
      content: "\f09b";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: #B4C9D1;
      margin-right: 0.5rem; }
    .front-hero .watch #twitter :before {
      content: "\f099";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: #B4C9D1;
      margin-right: 0.5rem; }
  .front-hero .tagline {
    width: calc(66.66667% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .front-hero .tagline {
        width: calc(41.66667% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
        padding-top: 6rem; } }
  .front-hero h1, .front-hero .h1 {
    color: #fff;
    font-weight: 500;
    font-size: 2.2rem; }
    @media print, screen and (min-width: 40em) {
      .front-hero h1, .front-hero .h1 {
        font-size: 2.875rem; } }
  .front-hero h4, .front-hero .h4 {
    color: #fefefe;
    font-weight: 300;
    font-size: 1.3125rem; }
  .front-hero .download {
    margin-top: 1.25rem; }

.intro {
  max-width: 75rem;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  .intro .fp-intro {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .intro .fp-intro {
        width: calc(83.33333% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem;
        margin-left: calc(8.33333% + 0.625rem); }
        .intro .fp-intro h2, .intro .fp-intro .h2 {
          font-weight: 300;
          margin-bottom: 1.5rem; }
        .intro .fp-intro h4, .intro .fp-intro .h4 {
          font-size: 1.125rem;
          line-height: 1.6;
          color: #777;
          margin-bottom: 2rem; } }

.section-divider {
  max-width: 75rem;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
  .section-divider hr {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    box-sizing: border-box; }

.benefits {
  max-width: 75rem;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  text-align: center; }
  .benefits header {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    .benefits header h2, .benefits header .h2 {
      font-weight: 300; }
    .benefits header h4, .benefits header .h4 {
      font-size: 1.125rem;
      line-height: 1.6;
      color: #777; }
  .benefits .semantic,
  .benefits .responsive,
  .benefits .customizable,
  .benefits .professional {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem; }
    @media print, screen and (min-width: 40em) {
      .benefits .semantic,
      .benefits .responsive,
      .benefits .customizable,
      .benefits .professional {
        width: calc(25% - 1.875rem);
        margin-right: 0.9375rem;
        margin-left: 0.9375rem; } }
    .benefits .semantic img,
    .benefits .responsive img,
    .benefits .customizable img,
    .benefits .professional img {
      padding: 1.25rem;
      margin: 1rem auto 0 auto; }
    .benefits .semantic h3, .benefits .semantic .h3,
    .benefits .responsive h3,
    .benefits .responsive .h3,
    .benefits .customizable h3,
    .benefits .customizable .h3,
    .benefits .professional h3,
    .benefits .professional .h3 {
      color: #0a0a0a;
      font-weight: 300;
      font-size: 1.75rem; }
    .benefits .semantic p,
    .benefits .responsive p,
    .benefits .customizable p,
    .benefits .professional p {
      font-size: 0.9375rem; }
  .benefits .why-foundation {
    width: calc(100% - 1.25rem);
    margin-right: 0.625rem;
    margin-left: 0.625rem;
    margin-top: 4rem; }

.kitchen-sink-header {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem; }

.kitchen-sink-components {
  width: calc(100% - 1.25rem);
  margin-right: 0.625rem;
  margin-left: 0.625rem; }
  .kitchen-sink-components hr {
    margin: 3rem 0; }
  @media print, screen and (min-width: 64em) {
    .kitchen-sink-components {
      width: calc(75% - 1.875rem); } }

.kitchen-sink-nav {
  display: none; }
  @media print, screen and (min-width: 64em) {
    .kitchen-sink-nav {
      width: calc(25% - 1.875rem);
      display: block;
      float: right; }
      .kitchen-sink-nav .docs-sub-menu {
        font-size: 0.85rem;
        margin-top: 1rem; }
      .kitchen-sink-nav .docs-menu-title {
        text-transform: uppercase;
        font-size: 0.75rem;
        color: #8a8a8a;
        font-weight: bold;
        line-height: 1;
        padding-left: 0.9rem;
        margin-bottom: 0.5rem;
        border-top: 1px solid #cacaca;
        margin-top: 1rem;
        padding-top: 1.5rem; }
      .kitchen-sink-nav .docs-toc {
        width: 100%;
        padding-left: 4rem;
        padding-right: 1rem; }
      .kitchen-sink-nav .docs-toc .active {
        background: #2199e8;
        color: white; }
      .kitchen-sink-nav .docs-toc .docs-sub-menu:first-child .docs-menu-title {
        margin-top: 0;
        border-top: 0;
        padding-top: 0; } }

.kitchen-sink-grid .grid-x {
  background: #d6ecfa;
  font-size: 12px;
  line-height: 2rem;
  margin-bottom: 1.5rem; }
  .kitchen-sink-grid .grid-x .cell, .kitchen-sink-grid .grid-x :nth-child(odd) {
    background: #7bc1ef;
    color: #0a0a0a; }
  .kitchen-sink-grid .grid-x .cell:nth-child(even) {
    background: #1779ba;
    color: #fefefe; }
